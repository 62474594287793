import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { DOCUMENT } from '@angular/common';
import { Component, inject, Inject } from '@angular/core';

import { ButtonComponent } from '@app/components/button/button.component';
import { ButtonData } from '@app/models/cookies.model';

@Component({
    selector: 'app-notice',
    standalone: true,
    imports: [ButtonComponent],
    templateUrl: './notice.component.html',
    styleUrl: './notice.component.sass',
})
export class NoticeComponent {
    public messageOptions: { message: string; title: string }[] = [
        {
            title: '',
            message:
                'Para mandar una <b>solicitud</b> de <b>intercambio</b>, es necesario que tengas cosas o chambas publicadas en tu perfil.',
        },
        {
            title: '¿Qué crees?',
            message:
                'Para mandar un solicitud de intercambio, es necesario que tengas cosas o chambas publicadas en tu perfil.',
        },
        {
            title: '¡Oops!',
            message:
                'No puedes mandar esta solicitud. Primero debes publicar al menos, una chamba o cosa en tu perfil.',
        },
        {
            title: '¡Híjole! ¿Qué crees?',
            message:
                'Antes de enviar una solicitud, primero debes tener algo publicado en tu perfil',
        },
        {
            title: '',
            message:
                '¡No tienes chambas o cosas en tu perfil! Para mandar esta solicitud debes publicar algo. <b>¿Quieres hacerlo ahora?</b>',
        },
        {
            title: '¡Oh, no!',
            message:
                'Aún no tienes chambas o cosas publicadas. Para enviar esta solicitud primero debes publicar algo.',
        },
        {
            title: 'Ahora sí viene lo bueno',
            message: '¿Estás súperseguro(a) de hacer el intercambio?',
        },
        {
            title: 'Super pregunta:',
            message:
                '¿Estás súperseguro(a) que quieres rechazar el intercambio?',
        },
        {
            title: '-',
            message: `Tu cuenta no existe.<br> Por favor inicia tu registro`,
        },
        {
            title: '-',
            message: `Tu cuenta ya existe.<br> Inicia sesión`,
        },
        {
            title: '¡Hey!',
            message: `Tienes una publicación guardada sin publicar`,
        },
        {
            title: '¡Oh, no!',
            message: `¿Seguro(a) que quieres eliminar esta publicación?`,
        },
        {
            title: '¡Genial!',
            message: `¿Todo listo? Estás a punto de publicar.`,
        },
        {
            title: '¡Híjole! ¿Qué crees?',
            message: `Esta publicación se guardará, recuerda darle a publicar después de que todo este bien.`,
        },
        {
            title: '¡Hey!',
            message: `Tienes una publicación guardada sin publicar`,
        },
        {
            title: '',
            message: `¿Estas seguro que deseas eliminar esta notificación?`,
        },
        {
            title: '¿Ya quedaron todos los detalles?',
            message:
                'Si aún quedan pendientes los detalles para cerrar el trato (cómo fecha o lugar de entrega), sigue platicando con la otra persona.',
        },
    ];

    // todo: data de ejemplo
    public message: { message: string; title: string } = {
        message: '',
        title: '',
    };
    public btnOperation: ButtonData[] = [
        {
            customClass: 'big-btn-font cookies-button',
            type: 'emit',
            rounded: true,
            uppercase: true,
            disabled: false,
            title: '',
            style: 'primary',
            width: '60px',
        },
        {
            customClass: 'big-btn-font cookies-button',
            type: 'emit',
            rounded: true,
            uppercase: true,
            disabled: false,
            title: '',
            style: 'secondary',
            width: '60px',
        },
    ];

    public iconModalDecorationPath: string =
        'assets/modal/vicente-for-notice-modal.svg';
    public showDecoration: boolean = true;

    private readonly document = inject(DOCUMENT);

    constructor(
        public dialogRef: DialogRef<any>,
        @Inject(DIALOG_DATA) public data: { option: number; message: string }
    ) {
        this.structureMessage(data.option, data.message);
    }

    ngOnDestroy(): void {
        this.document.body.style.overflowX = 'hidden';
        this.document.body.style.overflowY = 'auto';
    }

    structureMessage(option: number, message: string): void {
        try {
            this.message = this.messageOptions[option];

            if (option === 2) {
                this.btnOperation[0].title = 'Publicar algo';
                this.btnOperation[0].value = true;
                this.btnOperation[1].title = 'Entendido';
                this.btnOperation[1].value = null;
            } else if (option == 6 || option == 7) {
                this.btnOperation[0].title =
                    option == 6 ? 'Aceptar' : 'Rechazar';
                this.btnOperation[0].value = true;
                this.btnOperation[1].title = 'Cancelar';
                this.btnOperation[1].value = null;
            } else if (option == 8 || option == 9) {
                this.message.title = message;

                this.btnOperation[0].title = 'Aceptar';
                this.btnOperation[0].value = true;
                this.btnOperation[1].title = 'Modificar datos';
                this.btnOperation[1].value = null;
            } else if (option == 10) {
                this.btnOperation[0].title = 'Editar publicación';
                this.btnOperation[0].value = false;
                this.btnOperation[1].title = 'Eliminar publicación';
                this.btnOperation[1].value = true;
                this.btnOperation[1].style = 'danger';
            } else if (option == 11) {
                this.btnOperation[0].title = 'Eliminar publicación';
                this.btnOperation[0].value = true;
                this.btnOperation[0].style = 'danger';
                this.btnOperation[1].title = 'Cancelar';
                this.btnOperation[1].value = false;
                this.btnOperation[1].style = 'third';
            } else if (option == 12 || option == 13) {
                this.btnOperation[0].title = 'Continuar';
                this.btnOperation[0].value = true;
                this.btnOperation[1].title = 'Cancelar';
                this.btnOperation[1].value = false;
                this.btnOperation[1].style = 'third';
            } else if (option == 14) {
                this.btnOperation[0].title =
                    'Seguir editando tu publicación anterior';
                this.btnOperation[0].width = '200px';
                this.btnOperation[0].value = false;
                this.btnOperation[0].style = 'primary';
                this.btnOperation[1].title = 'Publicación nueva';
                this.btnOperation[1].value = true;
                this.btnOperation[1].style = 'third';
                this.btnOperation[1].width = '200px';
            } else if (option == 15) {
                this.btnOperation[0].title = 'Eliminar notificación';
                this.btnOperation[0].value = true;
                this.btnOperation[0].style = 'danger';
                this.btnOperation[1].title = 'Cancelar';
                this.btnOperation[1].value = false;
                this.btnOperation[1].style = 'third';
            } else if (option == 16) {
                this.btnOperation[0].title = 'Cerrar trato';
                this.btnOperation[0].value = true;
                this.btnOperation[1].title = 'Regresar al chat';
                this.btnOperation[1].value = null;
            }
        } catch (error) {
            throw new Error(
                'Error al obtener la información del modal el id del arreglo puede exceder su contenido'
            );
        }
    }

    register(data: ButtonData): void {
        this.dialogRef.close(data.value);
    }
}
